body {
  --icon-fill: transparent;
  --icon-stroke: #f80000;
  --primary-color: #f80000;
  --primary: #f80000;
  --card-shadow: 0px 1px 2px #f8000017, 0px 0px 4px #f8000017;
  --shadow-base: 0px 4px 8px #f8000017, 0px 0px 4px #f8000017;
}

.navbar.navbar-expand.sticky-top {
  background: #fff0f5;
}

.filter-icon.active use {
  stroke: #f80000;
}

#navbar-search {
  border: 1px solid #f80000;
}

.btn-primary-light {
  background: rgba(248, 0, 0, 0.0901960784);
  border-color: rgba(248, 0, 0, 0.0901960784);
}

.page-form .filter-button.btn-primary-light {
  color: #f80000;
}

.page-title .title-area .title-text {
  color: #f80000;
}

.btn-primary:not(:disabled):not(.disabled):active, div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, div#driver-popover-item .driver-popover-footer button.driver-next-btn:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle, div#driver-popover-item .driver-popover-footer .show > button.dropdown-toggle.driver-next-btn {
  color: #fff;
  background-color: #f80000;
  border-color: #ff0000;
}

.desk-sidebar-item.standard-sidebar-item {
  padding: 5px;
}

.navbar {
  max-height: 60px;
  height: 60px;
}

#navbar-search {
  height: 37px;
}

.page-card-head {
  margin-top: 100px;
}

.widget.onboarding-widget-box {
  display: none;
}

.widget[aria-label*=Learn] {
  display: none;
}